import React from 'react'
import Logo from '../Logo/Logo'
import {Link} from "react-scroll";

export const Header = () => {
  return (
    <nav className="navbar is-transparent">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <Logo/>
          </a>
        </div>
        <div className="navbar-menu" id="navMenu">
          <div className="navbar-item">
            <Link className="button is-primary is-outlined is-rounded"
                  to="footer"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}>
              <span>Say Hello</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
};

export default Header
