import React from "react"
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import ArrowImage from "./ArrowImage";

const BPMuaImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "portfolio/bpmua.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>
      <figure className="img-figure">
        <Img className="portfolio-img" fluid={data.placeholderImage.childImageSharp.fluid}
             alt="mateannasmink"/>
        <figcaption>
          <h1 className="title">Portfolio page for a make-up artist located in Budapest</h1>
          <a className="button is-primary is-outlined is-rounded"
             href="https://mateannasmink.hu/"
             target="_blank"
             rel="noopener noreferrer">
            <span>Visit Website</span>
            <span
              className="icon">
                <ArrowImage/>
                </span>
          </a>
        </figcaption>
        <div className="overlay"/>
      </figure>
    }
  />
)
export default BPMuaImage
