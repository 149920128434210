import React from "react"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

const HboImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "clients/hbo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img className="client-img hbo-img" fluid={data.placeholderImage.childImageSharp.fluid} alt="hbo"/>}
  />
)
export default HboImage
