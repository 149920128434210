import React from 'react'
import ProfileImage from "./Images/ProfileImage";
import HeroImage from "./Images/HeroImage";

const Hero = () => (
  <section className="hero">
    <div className="hero-body">
      <div className="container">
        <h1>Akos Horvath<br/>Front-end developer</h1>
        <h2>I develop beautiful handcrafted web apps, and I love what I do.</h2>
        <ProfileImage/>
      </div>
    </div>
    <div className="hero-foot">
      <div className="container">
        <HeroImage/>
      </div>
    </div>
  </section>
)

export default Hero
