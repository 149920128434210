import React from 'react'
import AttrectoImage from "./Images/AttrectoImage";
import HboImage from "./Images/HboImage";
import SuperchargeImage from "./Images/SuperchargeImage";

const Clients = () => (
  <section className="section clients is-medium is-white has-text-centered">
    <div className="container">
      <div className="columns">
        <div className="column">
          <h1 className="title">I'm proud to have collaborated with
            some awesome companies:</h1>
        </div>
      </div>
      <div className="client-grid">
        <div className="column">
          <AttrectoImage/>
        </div>
        <div className="column">
          <HboImage/>
        </div>
        <div className="column">
          <SuperchargeImage/>
        </div>
      </div>
    </div>
  </section>
)

export default Clients
