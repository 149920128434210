import React from 'react'
import Logo from '../Logo/Logo'
import GatsbyImage from "./Images/GatsbyImage";
import EnvelopeImage from "./Images/EnvelopeImage";
import LinkedinImage from "./Images/LinkedinImage";

const Footer = () => (
  <footer id="footer" className="accent">
    <div className="container is-narrow">
      <div className="logo-container">
        <Logo color="#fff"/>
      </div>
      <div className="columns is-centered">
        <div className="column">
          <h1 className="title">Living, learning, &amp; leveling up one day at a time.</h1>
        </div>
      </div>
      <div className="social-icons">
        <p className="field">
          <a className="button" href="https://www.linkedin.com/in/horvathakosistvan" target="_blank"
             rel="noopener noreferrer">
          <span className="icon is-small">
            <LinkedinImage/>
         </span>
          </a>
          <a className="button"
             href="mailt&#111;&#58;%&#54;&#56;&#111;&#114;vath%2&#69;a&#107;%6F&#115;%2E7&#64;gmai%6&#67;&#37;2&#69;%6&#51;%6Fm"
             target="_blank" rel="noopener noreferrer">
          <span className="icon is-small">
            <EnvelopeImage/>
         </span>
          </a>
        </p>
      </div>
      <div className="copyright">
        &copy;&nbsp;{new Date().getFullYear()}
        <br/>
        Handcrafted by me
      </div>
      <div className="made-with-gatsby">
        <div>Made with Gatsby</div>
        <a href="https://www.gatsbyjs.org/">
          <GatsbyImage/>
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
