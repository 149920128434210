import React from "react"
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const AudiQuizImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "portfolio/quiz.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>
      <figure className="img-figure no-button">
        <Img className="portfolio-img" fluid={data.placeholderImage.childImageSharp.fluid}
             alt="insider quiz"/>
        <figcaption>
          <h1 className="title">Insider quiz web application for a big automotive company</h1>
        </figcaption>
        <div className="overlay"/>
      </figure>
    }
  />
)
export default AudiQuizImage
