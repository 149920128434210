import React from 'react'

const Intro = () => (
  <section className="intro">
    <div className="container">
      <h1 className="title is-spaced is-size-3-desktop is-size-4-mobile">Hi, I’m Akos. Nice to meet you.</h1>
      <h2 className="subtitle is-size-5-desktop">Since beginning my journey as a front-end developer nearly 4 years
        ago, I've done remote work on streaming platforms, worked for agencies and collaborated with talented people
        to create digital products for both business and consumer use. I'm quietly confident, naturally curious,
        and perpetually improving my skills.</h2>
    </div>
  </section>
)

export default Intro
