import React from 'react'
import HboGoImage from "./Images/HboGoImage";
import HboFaqImage from "./Images/HboFaqImage";
import MateAnnaSminkImage from "./Images/MateAnnaSminkImage";
import BPMuaImage from "./Images/BPMuaImage";
import AudiQuizImage from "./Images/AudiQuizImage";
import CMSImage from "./Images/CMSImage";

const Portfolio = () => (
  <section className="section portfolio">
    <div className="container">
      <h1 className="title">My Recent Work</h1>
      <h2 className="subtitle is-size-5-desktop">Here are a few recent design projects.</h2>
      <div className="project-grid">
        <div className="columns">
          <div className="column">
            <HboGoImage/>
          </div>
          <div className="column">
            <HboFaqImage/>
          </div>
          <div className="column">
            <MateAnnaSminkImage/>
          </div>
          <div className="column">
            <BPMuaImage/>
          </div>
          <div className="column">
            <AudiQuizImage/>
          </div>
          <div className="column">
            <CMSImage/>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Portfolio
