import React from "react"
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const AttrectoImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "clients/attrecto.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img className="client-img attrecto-img" fluid={data.placeholderImage.childImageSharp.fluid}
                         alt="attrecto"/>}
  />
)
export default AttrectoImage
