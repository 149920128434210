import React from 'react'
import IconFrontend from "./Icons/IconFrontend";


export const Skills = () => (
  <section className="skills">
    <div className="container">
      <div className="column">
        <IconFrontend/>
        <h1 className="title is-size-4 is-spaced">Front-end Developer</h1>
        <p>I tend to code things from scratch, and enjoy bringing ideas to life in the browser.</p>
        <p className="list-title text-primary">Languages I speak:</p>
        <p>HTML, CSS, Sass, Javascript, Typescript</p>
        <p className="list-title text-primary">Frameworks:</p>
        <ul>
          <li>VueJs</li>
          <li>Angular</li>
          <li>React</li>
          <li>Bootstrap</li>
        </ul>
        <p className="list-title text-primary">Dev Tools:</p>
        <ul>
          <li>WebStorm</li>
          <li>Git</li>
          <li>Webpack</li>
          <li>Gulp</li>
          <li>Terminal</li>
        </ul>
      </div>
    </div>
  </section>
)

export default Skills
