import React from "react"
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import ArrowImage from "./ArrowImage";

const HboFaqImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "portfolio/hbo-help.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>
      <figure className="img-figure">
        <Img className="portfolio-img hbo-faq-img" fluid={data.placeholderImage.childImageSharp.fluid}
             alt="hbo-faq"/>
        <figcaption>
          <h1 className="title">Help Center for HBO GO streaming app, classic faq with modern technologies.</h1>
          <a className="button is-primary is-outlined is-rounded"
             href="https://helpcenter.hbogo.eu"
             target="_blank"
             rel="noopener noreferrer">
            <span>Visit Website</span>
            <span
              className="icon">
                <ArrowImage/>
                </span>
          </a>
        </figcaption>
        <div className="overlay"/>
      </figure>
    }
  />
)
export default HboFaqImage
