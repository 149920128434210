import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero/Hero'
import Intro from "../components/Intro/Intro";
import Skills from "../components/Skills/Skills";
import Clients from "../components/Clients/Clients";
import Portfolio from "../components/Portfolio/Portfolio";

const KEYWORDS = [`front-end`, `development`, `react`, 'gatsbyjs', 'javascript', 'angular', 'framework', 'developer']

const IndexPage = () => (
  <Layout>
    <Seo title="Home" keywords={KEYWORDS}/>
    <Hero/>
    <Intro/>
    <Skills/>
    <Portfolio/>
    <Clients/>
  </Layout>
)

export default IndexPage
