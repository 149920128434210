import React from "react"
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

const SuperchargeImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "clients/supercharge.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img className="client-img supercharge-img" fluid={data.placeholderImage.childImageSharp.fluid} alt="supercharge"/>}
  />
)
export default SuperchargeImage
